import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import PriceList from "./assets/img/jpeg/menu.png";
import PriceListKorean from "./assets/img/jpeg/menu-korean.png";

const resources = {
  en: {
    translation: {
      // home
      "our services": "our services",
      "Book Now": "Book Now",
      "Random staff": "Random staff",
      "Staff selecting": "Staff selecting",
      "Explore": "Explore",
      "Ticket packages": "Ticket packages",
      "Body massage": "Body massage",
      "Massage service starting at $12": "Massage service starting at $12",
      "services": {
        "title": "services",
        "items": {
          "title_1": "Relaxing Head Massages",
          "info_1": "Experience ultimate relaxation with our soothing head massage, designed to relieve tension and stress. Available 24/7 for your convenience.",

          "title_2": "Relaxing head/foot/shoulder massage",
          "info_2": "Designed to relieve tension and stress.",
          
          "title_3": "Comfortable stay packages",
          "info_3": "Our services are available around the clock to fit your schedule.",

          "title_4": "Spine adjustment therapy",
          "info_4": "Aimed at improving alignment and overall well-being."
        }
      },
      "priceList": PriceList,
      "select_staffs": "select staffs",

      // navbar
      "type_to_search": "Type to search...",

      // staffrandom
      "staff_random_banner": "Massage service at home and hotel in Da Nang",

      // OrderRandomCard
      "quiet_place_1": "Absolute silence - no disturbance",
      "quiet_place_2": "Normal silence - little communication",
      "quiet_place_3": "Vibrant space",
      "stop_booking": "00:00 temporarily stop accepting bookings for the day.",
      "start_booking": "01:00 can make an appointment for tomorrow.",
      "booking_note": "Freeship - all inclusive price (tip included), please only pay the exact ticket price",
      
      // CheckoutCard
      "error_name": "Please enter your name",
      "error_phone_number": "Please enter a valid phone number",
      "error_address": "Please enter your address",
      "error_payment_method": "Please choose a payment method",
      "shipping_information": "Shipping information",
      "payment_method": "Payment method",
      "note_description": "Pay directly to the employee only when the employee has arrived. (No transfer before staff arrival)",
      "note_extra": "Tickets include tip + transportation fee (Please pay only the correct fare)",
      "order_summary": "Order summary",
      "return_to_staffs": "Return to staffs",

      // Thankyou
      "thank_you": "Thank you for your purchase",

      // Invoice
      "here_invoice": "Here is your invoice",

    }
  },
  vi: {
    translation: {
      // home
      "our services": "Các dich vụ",
      "Book Now": "Đặt ngay",
      "Random staff": "Nhân viên ngẫu nhiên",
      "Staff selecting": "Chọn nhân viên",
      "Explore": "Khám phá",
      "Ticket packages": "Gói vé",
      "Body massage": "Body massage",
      "Massage service starting at $12": "Gói massage chỉ từ 280.000VND",
      "services": {
        "title": "Dịch vụ",
        "items": {
          "title_1": "Massage đầu",
          "info_1": "Trải nghiệm sự thư giãn tuyệt đối với dịch vụ massage đầu dịu nhẹ của chúng tôi, giúp giảm căng thẳng và stress. Dịch vụ 24/7",

          "title_2": "Massage đầu/chân/vai",
          "info_2": "Giúp giảm căng thẳng và stress.",

          "title_3": "Sẵn sàng 24/7",
          "info_3": "Dịch vụ sẵn sàng 24/7 để phù hợp với lịch trình của bạn.",

          "title_4": "Liệu pháp điều chỉnh cột sống",
          "info_4": "Nhằm cải thiện sự thẳng hàng và sức khỏe tổng thể."
        }
      },
      "priceList": PriceList,
      "select_staffs": "Chọn nhân viên",

      // navbar
      "type_to_search": "Tìm kiếm...",

      // staffrandom
      "staff_random_banner": "Dịch vụ massage tại nhà và khách sạn ở Đà Nẵng",

      // OrderRandomCard
      "quiet_place_1": "Hoàn toàn yên tĩnh - không bị làm phiền",
      "quiet_place_2": "Yên tĩnh bình thường - giao tiếp ít",
      "quiet_place_3": "Không gian sôi động",
      "stop_booking": "00:00 tạm ngừng nhận đặt chỗ trong ngày.",
      "start_booking": "01:00 có thể đặt chỗ cho ngày mai.",
      "booking_note": "Miễn phí vận chuyển - giá trọn gói (bao gồm tiền boa), vui lòng chỉ thanh toán đúng giá vé",

      // CheckoutCard
      "error_name": "Vui lòng nhập tên",
      "error_phone_number": "Vui lòng nhập số điện thoại hợp lệ",
      "error_address": "Vui lòng nhập địa chỉ",
      "error_payment_method": "Vui lòng chọn phương thức thanh toán",
      "shipping_information": "Thông tin giao hàng",
      "payment_method": "Phương thức thanh toán",
      "note_description": "Thanh toán trực tiếp cho nhân viên chỉ khi nhân viên đã đến. (Không chuyển khoản trước khi nhân viên đến)",
      "note_extra": "Vé bao gồm tiền boa + phí vận chuyển (Vui lòng chỉ thanh toán đúng giá vé)",
      "order_summary": "Đơn hàng",
      "return_to_staffs": "Chọn lại nhân viên",

      // Thankyou
      "thank_you": "Cảm ơn bạn đã đặt",

      // Invoice
      "here_invoice": "Đơn hàng của bạn"
    }
  },
  kr: {
    translation: {
      // home
      "our services": "우리의 서비스",
      "Book Now": "지금 예약",
      "Random staff": "무작위 직원",
      "Staff selecting": "직원 선택",
      "Explore": "탐색",
      "Ticket packages": "티켓 패키지",
      "Body massage": "전신 마사지",
      "Massage service starting at $12": "마사지 서비스 $12부터 시작",
      "services": {
        "title": "서비스",
        "items": {
          "title_1": "편안한 두피 마사지",
          "info_1": "긴장과 스트레스를 완화하도록 설계된 우리의 편안한 두피 마사지로 최고의 휴식을 경험해보세요. 24/7 이용 가능합니다.",
    
          "title_2": "편안한 두피/발/어깨 마사지",
          "info_2": "긴장과 스트레스를 완화하도록 설계되었습니다.",
    
          "title_3": "편안한 숙박 패키지",
          "info_3": "저희 서비스는 여러분의 일정에 맞추어 하루 종일 이용 가능합니다.",
    
          "title_4": "척추 조정 요법",
          "info_4": "정렬과 전반적인 웰빙을 개선하는 것을 목표로 합니다."
        }
      },
      "priceList": PriceListKorean,
      "select_staffs": "직원 선택",

      // navbar
      "type_to_search": "검색하려면 입력하세요...",

      // staffrandom
      "staff_random_banner": "다낭에서 집과 호텔에서 마사지 서비스",

      // OrderRandomCard
      "quiet_place_1": "절대적 침묵 - 방해 없음",
      "quiet_place_2": "일반적인 침묵 - 약간의 대화",
      "quiet_place_3": "활기찬 공간",
      "stop_booking": "00:00 오늘의 예약은 잠시 중단됩니다.",
      "start_booking": "01:00 내일의 예약이 가능합니다.",
      "booking_note": "무료 배송 - 전체 포함 가격 (팁 포함), 정확한 티켓 가격만 지불해주세요",

      // CheckoutCard
      "error_name": "이름을 입력하세요",
      "error_phone_number": "유효한 전화번호를 입력하세요",
      "error_address": "주소를 입력하세요",
      "error_payment_method": "결제 방법을 선택하세요",
      "shipping_information": "배송 정보",
      "payment_method": "결제 방법",
      "note_description": "직원이 도착한 후에만 직원에게 직접 결제하세요. (직원 도착 전에 이체하지 마세요)",
      "note_extra": "티켓에는 팁 + 운송비가 포함되어 있습니다 (정확한 요금만 지불해주세요)",
      "order_summary": "주문 요약",
      "return_to_staffs": "직원 목록으로 돌아가기",

      // Thankyou
      "thank_you": "구매해 주셔서 감사합니다",

      // Invoice
      "here_invoice": "여기에 귀하의 인보이스가 있습니다"
    }    
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
