import styled from "styled-components";
// import assets
import defaultImg from "./../../assets/img/jpeg/room-1.jpeg";

const StyledHero = styled.header`
  min-height: 40vh;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  background: url(${(props) => (props.img ? props.img : defaultImg)}) center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default StyledHero;
