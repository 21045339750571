import React, { Component } from "react";
import i18n from "i18next";

import defaultBcg from "../assets/img/jpeg/room-1.jpeg";

// import components
import Banner from "../Components/Banner/Banner";
import StyledHero from "../Components/StyledHero/StyledHero";
import OrderRandomCard from "../Components/Order/OrderRandomCard";

export default class StaffRandom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultBcg,
      price: "280.000",
    };
  }

  render() {
    return (
      <>
        <StyledHero img={this.state.defaultBcg}>
          <Banner title={i18n.t("staff_random_banner")} fontSize={24} >
          </Banner>
        </StyledHero>

        <OrderRandomCard />
      </>
    );
  }
}
