import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import i18n from "i18next";

import "../../App/CheckoutCard.css";
import { sendNotification } from "../../Utilities/telegram";

const CheckoutCard = () => {
  const [checkNote, setCheckNote] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [order, setOrder] = useState(null);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [errors, setErrors] = useState({});
  const history = useHistory();

  useEffect(() => {
    function getOrder() {
      const item = localStorage.getItem("order");
      setOrder(JSON.parse(item));
    }

    getOrder();
    window.addEventListener("storage", getOrder);

    return () => {
      window.removeEventListener("storage", getOrder);
    };
  }, []);

  const validate = () => {
    const newErrors = {};

    if (!name) newErrors.name = i18n.t("error_name");
    if (!phoneNumber || !isValidPhoneNumber(phoneNumber))
      newErrors.phoneNumber = i18n.t("error_phone_number");
    if (!address) newErrors.address = i18n.t("error_address");
    if (!checkNote) newErrors.note = i18n.t("error_payment_method");

    return newErrors;
  };

  const handleBooking = async () => {
    const newErrors = validate();

    if (Object.keys(newErrors).length !== 0) {
      setErrors(newErrors);
      return;
    }

    // send telegram noti
    // %0A: add new line
    sendNotification(
      `
      💆‍♀️*NEW ORDER:*%0A%0A

      🧚‍♂️*Khách:*%0A
      Tên:        ${name}%0A
      SĐT:        ${phoneNumber}%0A
      Địa chỉ:    ${address}%0A
      %0A

      🧚‍♀️*Nhân viên:*%0A
      STT:        ${order.staff.id}%0A
      Tên:        ${order.staff.name}%0A
      Chi tiết:   ${order.staff.details}%0A
      Gói:        ${order.staff.price}%0A
      `
    );

    // redirect to thankyou page (invoice)
    localStorage.setItem(
      "invoice",
      JSON.stringify({
        item: {
          staff: {
            id: order.staff.id,
            name: order.staff.name,
            details: order.staff.details,
            price: order.staff.price,
            image: order.staff.image,
          },
          total: order.total,
        },
        customer: {
          name: name,
          phoneNumber: phoneNumber,
          address: address,
        },
      })
    );
    history.push("/thankyou");
  };

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div className="checkout-container">
      <div className="checkout-content">
        <div className="shipping-info">
          <h2>{i18n.t("shipping_information")}</h2>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={errors.name ? "input-error" : ""}
          />
          {errors.name && <div className="error">{errors.name}</div>}
          <PhoneInput
            placeholder="Phone number"
            defaultCountry="VN"
            value={phoneNumber}
            onChange={setPhoneNumber}
            className={errors.phoneNumber ? "input-error-phone" : ""}
          />
          {errors.phoneNumber && (
            <div className="error">{errors.phoneNumber}</div>
          )}
          <input
            type="text"
            placeholder="Address hotel, home..."
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className={errors.address ? "input-error" : ""}
          />
          {errors.address && <div className="error">{errors.address}</div>}
          {errors.note && <div className="error error-note">{errors.note}</div>}
          <div className="note">
            <h4 className="text-start" style={{ fontSize: "1em" }}>
              {i18n.t("payment_method")}
            </h4>
            <div className="d-inline-flex">
              <input
                type="radio"
                value="direct"
                checked={checkNote}
                onChange={() => setCheckNote(true)}
              />
              <h5 className="note-description">
                {i18n.t("note_description")}
              </h5>
            </div>
            <label className="note-extra">
              {i18n.t("note_extra")}
            </label>
          </div>
        </div>
        <div className="order-summary">
          <h2>{i18n.t("order_summary")}</h2>
          <div className="product-item">
            <img src={order.staff.image} alt={order.staff.name} />
            <div>
              <h5>
                Number {order.staff.id}: {order.staff.name}
              </h5>
              <p>{order.staff.details}</p>
              <p>{order.staff.price}₫</p>
            </div>
          </div>
          <div className="total">
            <h5 className="m-0">Total</h5>
            <b>{order.total}₫</b>
          </div>
          <button className="book-now" onClick={handleBooking}>
            BOOK MASSAGE
          </button>
        </div>
      </div>
      <a href="/staffs" className="return-to-cart">
        {i18n.t("return_to_staffs")}
      </a>
    </div>
  );
};

export default CheckoutCard;
