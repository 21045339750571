import React, { Component } from "react";

// imports react-icons
import { FaCocktail, FaHiking, FaBeer, FaClock } from "react-icons/fa";
import i18n from "i18next";

// imports components
import Title from "../Title/Title";

export default class Services extends Component {
  render() {
    return (
      <section className="services">
        <Title title={i18n.t("services.title")} />

        <div className="services-center">
          {[
            {
              icon: <FaClock />,
              title: i18n.t("services.items.title_1"),
              info: i18n.t("services.items.info_1"),
            },
            {
              icon: <FaCocktail />,
              title: i18n.t("services.items.title_2"),
              info: i18n.t("services.items.info_2"),
            },
            {
              icon: <FaHiking />,
              title: i18n.t("services.items.title_3"),
              info: i18n.t("services.items.info_3"),
            },
            {
              icon: <FaBeer />,
              title: i18n.t("services.items.title_4"),
              info: i18n.t("services.items.info_4"),
            },
          ].map((item, index) => {
            return (
              <article key={index} className="services">
                <span>{item.icon}</span>
                <h6>{item.title}</h6>
                <p className="text-start">{item.info}</p>
              </article>
            );
          })}
        </div>
        <img
          src={i18n.t("priceList")}
          className="img-thumbnail img-price-list"
          alt="price-list"
        />
      </section>
    );
  }
}
