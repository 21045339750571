import React from "react";

function Banner({ children, title, subtitle, fontSize }) {
  return (
    <div className="banner">
      {fontSize ? <h1 style={{ fontSize }}>{title}</h1> : <h1>{title}</h1>}
      <p>{subtitle}</p>
      {children}
    </div>
  );
}

export default Banner;
