import React from "react";

// import components
import Staff from "../Staff/Staff";

export default function StaffsList({ staffs }) {
  return (
    <section className="staffslist">
      <div className="staffslist-center">
        {staffs.map((item) => {
          return <Staff key={item.id} staff={item} />;
        })}
      </div>
    </section>
  );
}
