import React from "react";
import { Link } from "react-router-dom";
import defaultImg from "../../assets/img/jpeg/room-1.jpeg";
import PropTypes from "prop-types";

import "../../App/App.css"; // Import the CSS file

export default function Staff({ staff }) {
  const { name, id, images } = staff;

  return (
    <article className="staff">
      <div className="img-container">
        <img src={images[0] || defaultImg} alt="staff" className="fixed-size-img" loading="lazy" />
        <Link to={`/staffs/${id}`} className="btn-primary staff-link">
          BOOK
        </Link>
      </div>
      <div className="staff-details">
        <h3 className="staff-id">NUMBER {id}: {name}</h3>
        <h5 className="staff-id fw-normal font-size-1">MASSAGE AT HOME DA NANG, VIET NAM</h5>
        <p className="staff-price">300.000₫</p>
      </div>
    </article>
  );
}

Staff.propTypes = {
  staff: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};
