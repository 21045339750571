import React from "react";

import Hero from "../Components/Hero/Hero";
import StaffContainer from "../Components/StaffsContainer/StaffsContainer";

function Staff() {
  return (
    <>
      <Hero hero="roomsHero">
      </Hero>

      <StaffContainer />
    </>
  );
}

export default Staff;
