import React from "react";

// import context
import { withStaffConsumer } from "../../Context/Context";

// import components
import Loadings from "../Loading/Loading";
import StaffFilter from "./StaffsFilter";
import StaffsList from "./StaffsList";

function StaffContainer({ context }) {
  const { loading, sortedStaffs, staffs } = context;

  if (loading) {
    return <Loadings />;
  }

  return (
    <>
      <StaffFilter staffs={staffs} />
      <StaffsList staffs={sortedStaffs} />
    </>
  );
}

export default withStaffConsumer(StaffContainer);
