import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import i18n from "i18next";

import Logo from "../../assets/img/svg/logo.png";
import { FaSearch } from "react-icons/fa";
import Vietnamese from "../../assets/img/jpeg/logo/vietnamese.png";
import English from "../../assets/img/jpeg/logo/english.png";
import Korean from "../../assets/img/jpeg/logo/korean.png";

class Navbar extends Component {
  state = {
    searchQuery: "",
  };

  changeLanguage = (langCode) => {
    const { history } = this.props;
    i18n.changeLanguage(langCode);
    history.push("/");
  };

  handleBookNow = () => {
    const { history } = this.props;
    history.push("/options");
  };

  handleSearchChange = (e) => {
    this.setState({ searchQuery: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { searchQuery } = this.state;
    const { history } = this.props;
    history.push(`/staffs?query=${searchQuery}`);
  };

  render() {
    return (
      <nav className="navbar">
        <div className="nav-center">
          <div className="nav-header my-1">
            <Link to="/" className="logo col-md-4">
              <img src={Logo} alt="MN Massage" className="img-fluid" />
            </Link>
          </div>
          <ul className="nav-links show-nav align-items-center">
            <li className="border border-light border-1 rounded">
              <Link to="/" onClick={() => this.changeLanguage("vi")} className="d-flex align-items-center">
                <img src={Vietnamese} width={25} height={15} alt="Vietnamese" />
                <h5 className="text-light mx-1">Vietnamese</h5>
              </Link>
            </li>
            <li className="border border-light border-1 rounded mx-1">
              <Link to="/" onClick={() => this.changeLanguage("en")} className="d-flex align-items-center">
                <img src={English} width={25} height={15} alt="English" />
                <h5 className="text-light mx-1">English</h5>
              </Link>
            </li>
            <li className="border border-light border-1 rounded">
              <Link to="/" onClick={() => this.changeLanguage("kr")} className="d-flex align-items-center">
                <img src={Korean} width={25} height={15} alt="Korean" />
                <h5 className="text-light mx-1">Korean</h5>
              </Link>
            </li>
            <li>
              <button
                type="button"
                className="btn btn-danger btn-book border-light border-2 rounded-pill mx-2"
                onClick={this.handleBookNow}
              >
                {i18n.t('Book Now')}
              </button>
            </li>
          </ul>
        </div>
        <div className="nav-center navbar-search">
          <form onSubmit={this.handleSubmit} className="d-flex w-100 align-items-center">
            <input
              className="flex-grow-1"
              type="text"
              placeholder={i18n.t("type_to_search")}
              value={this.state.searchQuery}
              onChange={this.handleSearchChange}
              maxLength={20}
              style={{fontSize: '0.8em'}}
            />
            <button type="submit" className="btn">
              <FaSearch />
            </button>
          </form>
        </div>
      </nav>
    );
  }
}

export default withRouter(Navbar);
