import "./App.css";

import { Route } from "react-router-dom";

// imports pages
import Home from "../Pages/Home";
import Staff from "../Pages/Staff";
import StaffDetail from "../Pages/StaffDetail";
import StaffRandom from "../Pages/StaffRandom";

// import Error from "../Pages/Error";
import Option from "../Pages/Option";
import Checkout from "../Pages/Checkout";
import Thankyou from "../Pages/Thankyou";

// import components
import Navbar from "../Components/Navbar/Navbar";

import "../i18n";

function App() {
  return (
    <>
      <Navbar />
      <Route>
        <Route exact path="/" component={Home} />
        <Route exact path="/staffs" component={Staff} />
        <Route exact path="/staffs/:id" component={StaffDetail} />
        <Route exact path="/staffrandom" component={StaffRandom} />
        <Route exact path="/options" component={Option} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/thankyou" component={Thankyou} />
        {/* <Route component={Error} /> */}
      </Route>
    </>
  );
}

export default App;
