import React from "react";
import FeaturedStaffs from "../Components/FeaturedStaffs/FeaturedStaffs";

function Option() {
  return (
    <FeaturedStaffs />
  )
}

export default Option;
