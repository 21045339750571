import React, { Component } from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";

// import context
import { StaffContext } from "../../Context/Context";

// import components
import Loading from "../Loading/Loading";
import Title from "../Title/Title";
import img8 from "../../assets/img/jpeg/room-8.jpeg";
import img12 from "../../assets/img/jpeg/room-12.jpeg";

export default class FeaturedStaffs extends Component {
  static contextType = StaffContext;

  render() {
    let { loading } = this.context;

    return (
      <section className="featured-rooms" style={{ padding: "1rem 0" }}>
        <Title title={i18n.t("Ticket packages")} id="ticket-packages" />

        <div className="featured-rooms-center" style={{ gap: "1rem" }}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <article className="room" style={{ marginBottom: "1rem" }}>
                <div className="img-container">
                  <img src={img8} alt="random staff" style={{ height: "100%", objectFit: "cover" }} />

                  <div className="price-top" style={{ right: "10px" }}>
                    <h6>From 280.000 VND</h6>
                  </div>

                  <Link to={`/staffrandom`} className="btn-primary room-link" style={{ padding: "0.2rem 0.5rem" }}>
                    {i18n.t("Explore")}
                  </Link>
                </div>
                <p className="staff-info">{i18n.t('Random staff')}</p>
              </article>
              <article className="room" style={{ marginBottom: "1rem" }}>
                <div className="img-container">
                  <img src={img12} alt="staff selecting" style={{ height: "100%", objectFit: "cover" }} />

                  <div className="price-top" style={{ right: "10px" }}>
                    <h6>From 300.000 VND</h6>
                  </div>

                  <Link to={`/staffs`} className="btn-primary room-link" style={{ padding: "0.2rem 0.5rem" }}>
                    {i18n.t("Explore")}
                  </Link>
                </div>
                <p className="staff-info">{i18n.t("Staff selecting")}</p>
              </article>
            </>
          )}
        </div>
      </section>
    );
  }
}
