import React from "react";
import i18n from "i18next";

import Banner from "../Components/Banner/Banner";
import InvoiceCard from "../Components/Invoice/InvoiceCard";
import StyledHero from "../Components/StyledHero/StyledHero";

import defaultBcg from "../assets/img/jpeg/room-1.jpeg";

function Thankyou() {
  return (
    <>
      <StyledHero img={defaultBcg}>
        <Banner title={i18n.t("thank_you")} fontSize={24} >
        </Banner>
      </StyledHero>
      <InvoiceCard />
    </>
  );
}

export default Thankyou;
