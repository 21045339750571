import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import i18n from "i18next";

import { StaffContext } from '../../Context/Context';
import Title from '../Title/Title';
import places from '../../Data/places.json';

const getUnique = (items, value) => {
  return [...new Set(items.map((item) => item[value]))];
};

class StaffFilter extends Component {
  static contextType = StaffContext;

  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(this.props.location.search); // Extract query params
    const query = urlParams.get('query') || ''; // Get the query parameter from URL

    this.state = {
      selectedCityId: '',
      selectedDistrictId: '',
      districts: [],
      cityNames: [],
      districtNames: [],
      query // Initialize query from URL param
    };
  }

  componentDidMount() {
    const cityNames = getUnique(places, 'Name');
    this.setState({ cityNames });

    // If query is not empty and city/district filters are empty, handle the query
    const { query, selectedCityId, selectedDistrictId } = this.state;
    if (query && !selectedCityId && !selectedDistrictId) {
      this.context.handleChange({ target: { name: 'query', value: query } });
    }
  }

  handleCityChange = (event) => {
    const selectedCityName = event.target.value;

    if (selectedCityName === '') {
      // Reset state when 'all' is selected
      this.setState({ 
        selectedCityId: '', 
        selectedDistrictId: '', 
        districts: [], 
        districtNames: [] 
      });

      // Notify context about the reset
      this.context.handleChange({ target: { name: 'city', value: '' } });
      this.context.handleChange({ target: { name: 'district', value: '' } });
    } else {
      const selectedPlace = places.find(place => place.Name === selectedCityName);
      const districts = selectedPlace ? selectedPlace.Districts.map(district => ({
        id: district.Id,
        name: district.Name
      })) : [];

      this.setState({ 
        selectedCityId: selectedPlace ? selectedPlace.Id : '', 
        selectedDistrictId: '', 
        districts, 
        districtNames: districts.map(district => district.name)
      });

      this.context.handleChange({ target: { name: 'city', value: selectedPlace ? selectedPlace.Id : '' } });
    }
  }

  handleDistrictChange = (event) => {
    const selectedDistrictName = event.target.value;

    if (selectedDistrictName === '') {
      // Reset district when 'all' is selected
      this.setState({ 
        selectedDistrictId: ''
      });

      // Notify context about the reset
      this.context.handleChange({ target: { name: 'district', value: '' } });
    } else {
      const selectedDistrict = this.state.districts.find(district => district.name === selectedDistrictName);

      this.setState({ 
        selectedDistrictId: selectedDistrict ? selectedDistrict.id : ''
      });

      this.context.handleChange({ target: { name: 'district', value: selectedDistrict ? selectedDistrict.id : '' } });
    }
  }

  render() {
    const { selectedCityId, selectedDistrictId, cityNames, districtNames } = this.state;

    return (
      <section className="filter-container">
        <Title title={i18n.t("select_staffs")} />
        <form className="filter-form">
          <div className="form-group d-flex justify-content-between">
            <div className="d-flex align-items-center flex-grow-1 mx-2">
              <label htmlFor="city" className="mx-1">City</label>
              <select
                name="city"
                id="city"
                value={cityNames.find(name => places.find(place => place.Id === selectedCityId)?.Name === name) || ''}
                className="form-control"
                onChange={this.handleCityChange}
              >
                <option value="all">--</option>
                {cityNames.map((cityName, index) => (
                  <option value={cityName} key={index}>{cityName}</option>
                ))}
              </select>
            </div>
            <div className="d-flex align-items-center flex-grow-1">
              <label htmlFor="district" className="mx-1">District</label>
              <select
                name="district"
                id="district"
                value={districtNames.find(name => this.state.districts.find(district => district.id === selectedDistrictId)?.name === name) || ''}
                className="form-control"
                onChange={this.handleDistrictChange}
                disabled={!selectedCityId}
              >
                <option value="all">--</option>
                {districtNames.map((districtName, index) => (
                  <option value={districtName} key={index}>{districtName}</option>
                ))}
              </select>
            </div>
          </div>
        </form>
      </section>
    );
  }
}

export default withRouter(StaffFilter); // Wrap component with withRouter
