import React from "react";

import Banner from "../Components/Banner/Banner";
import CheckoutCard from "../Components/Checkout/CheckoutCard";
import StyledHero from "../Components/StyledHero/StyledHero";

import defaultBcg from "../assets/img/jpeg/room-1.jpeg";

function Checkout() {
  return (
    <>
      <StyledHero img={defaultBcg}>
        <Banner title={"Checkout"} fontSize={24} >
        </Banner>
      </StyledHero>
      <CheckoutCard />
    </>
  );
}

export default Checkout;
