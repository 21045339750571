import React, { Component } from "react";
import { Link } from "react-router-dom";

import defaultBcg from "../assets/img/jpeg/room-1.jpeg";

// import components
import Banner from "../Components/Banner/Banner";
import { StaffContext } from "../Context/Context";
import StyledHero from "../Components/StyledHero/StyledHero";
import OrderCard from "../Components/Order/OrderCard";

export default class StaffDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      defaultBcg,
      price: "300.000",
    };
  }

  static contextType = StaffContext;

  render() {
    const { getStaff } = this.context;
    const staff = getStaff(this.state.id);

    if (!staff) {
      return (
        <div className="error">
          <h3>no staffs could be found!</h3>
          <Link to="/rooms" className="btn-primary">
            back to homepage
          </Link>
        </div>
      );
    }

    const {
      id,
    } = staff;

    return (
      <>
        <StyledHero img={this.state.defaultBcg}>
          <Banner title={`Staff #${id}: Massage at home in Da Nang`} fontSize={24} >
          </Banner>
        </StyledHero>

        <OrderCard item={staff} />
      </>
    );
  }
}
