import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link, useHistory } from "react-router-dom";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import i18n from "i18next";

import '../../App/OrderCard.css';
import defaultBcg from "../../assets/img/jpeg/defaultBcg2.jpg";

const OrderRandomCard = React.memo(() => {
  const [price, setPrice] = useState("280.000");
  const [serviceLength, setServiceLength] = useState("60 minutes");
  const [massageType, setMassageType] = useState("THAILAND MASSAGE");
  const [quietPlace, setQuietPlace] = useState(i18n.t("quiet_place_1"));
  const [activeMassageType, setActiveMassageType] = useState("1");
  const [activeServiceLength, setActiveServiceLength] = useState("60");
  const [activeQuietPlace, setActiveQuietPlace] = useState("1");
  const history = useHistory();

  const handleCheckoutPage = () => {
    localStorage.setItem("order", JSON.stringify({
      staff: {
        id: 0,
        name: "Random staff",
        details: massageType + " / " + serviceLength + " / " + quietPlace,
        price: price,
        image: defaultBcg
      },
      total: price
    }));
    history.push("/checkout");
  };

  const changeMassageType = (e) => {
    const value = e.target.value;
    setActiveMassageType(value);
    switch (value) {
      case "1":
        setMassageType("THAILAND MASSAGE");
        break;
      case "2":
        setMassageType("SWEDISH MASSAGE");
        break;
      default:
        setMassageType("THAILAND MASSAGE");
        break;
    }
  };

  const changeQuietPlace = (e) => {
    const value = e.target.value;
    setActiveQuietPlace(value);
    switch (value) {
      case "1":
        setQuietPlace(i18n.t("quiet_place_1"));
        break;
      case "2":
        setQuietPlace(i18n.t("quiet_place_2"));
        break;
      case "3":
        setQuietPlace(i18n.t("quiet_place_3"));
        break;
      default:
        setQuietPlace(i18n.t("quiet_place_1"));
        break;
    }
  };

  const changePrice = (e) => {
    const value = e.target.value;
    setActiveServiceLength(value);
    switch (value) {
      case "60":
        setPrice("280.000");
        setServiceLength("60 minutes");
        break;
      case "75":
        setPrice("330.000");
        setServiceLength("75 minutes");
        break;
      case "90":
        setPrice("380.000");
        setServiceLength("90 minutes");
        break;
      case "120":
        setPrice("480.000");
        setServiceLength("120 minutes");
        break;
      default:
        setPrice("280.000");
        setServiceLength("60 minutes");
        break;
    }
  };

  return (
    <>
      <div className="breadcrumb">
        <Link to="/">Home /</Link>
      </div>
      <div className="staff-card">
        <div className="card-header">
          <h4>RANDOM STAFF MASSAGE AT HOME IN DA NANG</h4>
        </div>
        <div className="card-content">
          <div className="image-gallery">
            <Carousel showThumbs={true} showArrows={false} showIndicators={false} showStatus={false}>
            {Array.from({ length: 3 }, () => defaultBcg).map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Gallery ${index}`} loading="lazy" />
              </div>
            ))}
            </Carousel>
          </div>
          <div className="details">
            <div className="top-details">
              <h6>SERVICE: <span className="fw-normal">MASSAGE AT HOME, APARTMENT, HOTEL</span></h6>
              <h6>TICKET TYPE: <span className="fw-normal">RANDOM STAFF</span></h6>
            </div>
            <div className="work-time">
              <h5 className="my-1"><b>Working Time:</b> From 08:00 to 02:00</h5>
              <h5 className="my-1"><b>After:</b> {i18n.t("stop_booking")}</h5>
              <h5 className="my-1"><b>After:</b> {i18n.t("start_booking")}</h5>
            </div>
            <div className="pricing">
              <article className="info text-success">
                <h4 className="my-0 mt-4">{price} VND</h4>
              </article>
              <h5>{i18n.t("booking_note")}</h5>
            </div>
            <div className="service-options">
              <button className={activeMassageType === "1" ? "active" : ""} onClick={changeMassageType} value="1">THAILAND MASSAGE</button>
              <button className={activeMassageType === "2" ? "active" : ""} onClick={changeMassageType} value="2">SWEDISH MASSAGE</button>
            </div>
            <div className="time-options">
              <h6 className="mb-2">SERVICE TIME</h6>
              <button className={activeServiceLength === "60" ? "highlight" : ""} onClick={changePrice} value="60">60 minutes</button>
              <button className={activeServiceLength === "75" ? "highlight" : ""} onClick={changePrice} value="75">75 minutes</button>
              <button className={activeServiceLength === "90" ? "highlight" : ""} onClick={changePrice} value="90">90 minutes</button>
              <button className={activeServiceLength === "120" ? "highlight" : ""} onClick={changePrice} value="120">120 minutes</button>
            </div>
            <div className="space-options">
              <h6 className="mb-2">QUIET PLACE</h6>
              <button className={activeQuietPlace === "1" ? "active" : ""} onClick={changeQuietPlace} value="1">{i18n.t("quiet_place_1")}</button>
              <button className={activeQuietPlace === "2" ? "active" : ""} onClick={changeQuietPlace} value="2">{i18n.t("quiet_place_2")}</button>
              <button className={activeQuietPlace === "3" ? "active" : ""} onClick={changeQuietPlace} value="3">{i18n.t("quiet_place_3")}</button>
            </div>
            <div className="actions">
              <button className="select-button" onClick={handleCheckoutPage}>SELECT</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default OrderRandomCard;
