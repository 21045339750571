import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import i18n from "i18next";

import '../../App/InvoiceCard.css';

const InvoiceCard = () => {
  const [invoice, setInvoice] = useState(null);
  const history = useHistory();

  useEffect(() => {
    function getInvoice() {
      const item = localStorage.getItem("invoice");
      setInvoice(JSON.parse(item));
    }

    getInvoice();
    window.addEventListener('storage', getInvoice);

    return () => {
      window.removeEventListener('storage', getInvoice);
    };
  }, []);

  if (!invoice) {
    return <div>Loading...</div>;
  }

  const toHomePage = () => {
    localStorage.removeItem("order");
    localStorage.removeItem("invoice");
    history.push("/");
  };

  return (
    <div className="checkout-container">
      <h3 style={{textAlign: 'center'}}>{i18n.t("here_invoice")}</h3>
      <div className="checkout-content">
        <div className="invoice-order-summary">
          <h4>Your Order</h4>
          <div className="product-item">
            <img src={invoice.item.staff.image} alt={invoice.item.staff.name} />
            <div>
              <h5>Number {invoice.item.staff.id}: {invoice.item.staff.name}</h5>
              <p>{invoice.item.staff.details}</p>
              <p>{invoice.item.staff.price}₫</p>
            </div>
          </div>
          <div className="total">
            <h5 className="m-0">Total</h5>
            <b>{invoice.item.total}₫</b>
          </div>
        </div>
        <div className="invoice-shipping-info">
          <h4>Payment information</h4>
          <div className="invoice-product-item">
            <h5>Name: <b>{invoice.customer.name}</b></h5>
            <h5>Phone number: <b>{invoice.customer.phoneNumber}</b></h5>
            <h5>Address: <b>{invoice.customer.address}</b></h5>
          </div>
          <h4>Payment method</h4>
          <h5>{i18n.t("note_description")}</h5>
        </div>
        <button className="continue-shopping" onClick={toHomePage}>Continue shopping</button>
      </div>
    </div>
  );
};

export default InvoiceCard;
