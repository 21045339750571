import React from "react";
// import react-router-dom
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

// imports components
import Banner from "../Components/Banner/Banner";
import FeaturedStaffs from "../Components/FeaturedStaffs/FeaturedStaffs";
import Hero from "../Components/Hero/Hero";
import Services from "../Components/Services/Services";

function Home() {
  const { t } = useTranslation()

  return (
    <>
      <Hero>
        <Banner
          title={i18n.t("Body massage")} subtitle={i18n.t("Massage service starting at $12")}
        >
          <Link to="/options" className="btn-primary">
            {t('our services')}
          </Link>
        </Banner>
      </Hero>
      <Services />
      <FeaturedStaffs />
    </>
  );
}

export default Home;
