import React, { Component } from "react";

// import data
import items from "../Data/staffs";

// react context-api
const StaffContext = React.createContext();

class StaffProvider extends Component {
  state = {
    staffs: [],
    sortedStaffs: [],
    loading: true,
    city: "all",
    district: "all",
    query: "" // Initialize query param
  };

  componentDidMount() {
    let staffs = this.formatData(items);

    this.setState({
      staffs,
      sortedStaffs: staffs,
      loading: false,
    });
  }

  formatData(items) {
    let tempItems = items.map((item) => {
      let id = item.id;
      let images = item.images;

      let staff = { ...item, images, id };
      return staff;
    });
    return tempItems;
  }

  getStaff = (id) => {
    let tempStaffs = [...this.state.staffs];
    const staff = tempStaffs.find((staff) => staff.id === id);
    return staff;
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;

    this.setState(
      {
        [name]: value,
      },
      this.filterStaffs
    );
  };

  filterStaffs = () => {
    let {
      staffs,
      query,
      city,
      district,
    } = this.state;

    // all the staffs
    let tempStaffs = [...staffs];

    // filter name by query
    if (query && city === "all" && district === "all") {
      tempStaffs = tempStaffs.filter((staff) => staff.name.includes(query));
    }

    // filter by city
    if (city && city !== "all") {
      tempStaffs = tempStaffs.filter((staff) => staff.city === city);
    }

    // filter by district
    if (district && district !== "all") {
      tempStaffs = tempStaffs.filter((staff) => staff.districts.includes(district));
    }

    // change state
    this.setState({
      sortedStaffs: tempStaffs,
    });
  };

  render() {
    return (
      <StaffContext.Provider
        value={{
          ...this.state,
          getStaff: this.getStaff,
          handleChange: this.handleChange,
        }}
      >
        {this.props.children}
      </StaffContext.Provider>
    );
  }
}

const StaffConsumer = StaffContext.Consumer;

export function withStaffConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <StaffConsumer>
        {(value) => <Component {...props} context={value} />}
      </StaffConsumer>
    );
  };
}

export { StaffProvider, StaffConsumer, StaffContext };
